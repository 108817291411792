.Title {
    margin: 0;
    font-size: 32px;
    line-height: 40px;
    font-weight: bold;
    color: #000;
}

.subtitle {

}

.Text {
    margin: 0;
}

.Text__lg {
    font-size: $textSizeLG;
    line-height: $textLineLG;
}

.Text__md {
    font-size: $textSizeMD;
    line-height: $textLineMD;
}

.Text__mdx {
    font-size: $textSizeMDx;
    line-height: $textLineMDx;
    font-weight: normal !important;
}

.Text__sm {
    font-size: $textSizeSM;
    line-height: $textLineSM;
}

.Text__muted {
    color: $color-gray-muted;
}

.Text__error {
    color: $color-red
}

.link {

}

.icon {

}

.list {

}

.break-all {
    word-break: break-all;
}

.nowrap {
    white-space: nowrap;
}

.gray {
    color: $color-gray;
}