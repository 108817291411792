.accordeon {
  width: 460px;
  padding: 30px 0;
}

.accordeon:not(:last-child) {
  border-bottom: 1px solid #E4EBF0;
}

.title {
  width: 460px;
  margin-top: 10px;
  color: #24282B;
}

.content {
  width: 400px;
  font-size: 16px;
  margin-top: 12px;
  color: #8C959C;
}

.content_hide {
  height: 0;
  overflow: hidden;
  margin-top: 0;
}

.iconsDefaultArrowsChe {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xs-5) var(--padding-12xs) 4.25px;
  align-items: flex-end;
  /* justify-content: center; */
}

.vectorIcon {
  position: relative;
  width: 14px;
  height: 8.25px;
}
