.Page {
}

.Page_title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 32px;
}

.Page_explan {
    width: 460px;
    margin-bottom: 18px;
    font-size: 18px;
}

.Page_offer {
    width: 460px;
    border-top: 1px solid #E4EBF0;
    padding: 25px 0px 0px 0px;
    // margin-top: 20px;
    // margin-bottom: 20px;
}
