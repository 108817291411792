@import "../../styles/common";

.DocumentsListItem {
    display: flex;
    align-items: center;
}

.DocumentsListItem_preview {
    flex: 0 0 auto;
    width: 40px;
    margin-right: 12px;

    img {
        display: block;
        max-width: 40px;
    }
}

.DocumentsListItem_info {
    flex: 1 1 auto;
}

.DocumentsListItem_title {
    margin-bottom: 4px;
}

.DocumentsListItem_meta {
    color: $color-gray-muted;
    display: flex;
    flex-wrap: wrap;
    & > * {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.DocumentsListItem_company {

}

.DocumentsListItem_person {

}