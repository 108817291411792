@import "../../styles/common";

.Footer {
    display: flex;
    align-items: center;

    @include mq(desktop) {
        padding: 25px 40px;
    }
    @include mq(mobile) {
        padding: 20px $padding-side;
    }
}

.Footer_copyright {
    font-size: 15px;
    color: #909090;
}