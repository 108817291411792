@import "../../styles/common";

.Layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    justify-content: flex-start;
}

.Layout_header {
}

.Layout_main {
    @include mq(desktop) {
        margin-bottom: 50px;
    }
    @include mq(mobile) {
        margin-bottom: 30px;
    }
}

.Layout_footer {
    margin-top: auto;
    margin-bottom: 0;
}