.DocumentsList {
}

.DocumentsList_rows {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
}

.DocumentsList_row {
    padding: 0;
    margin: 0 0 24px;
    &:last-child {
        margin-bottom: 0;
    }
}