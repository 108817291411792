// Responsive
$breakpoints: (
    mobile: 0 1023px,
    desktop: 1024px null
);

// Fonts
$font-regular: sans-serif;
$font-new: 'SF UI Display Regular', sans-serif;

// Sizes
$container-width: 560px;
$padding-side: 20px;
$corner-radius: 4px;

$textSizeLG: 17px;
$textLineLG: 24px;

$textSizeMD: 15px;
$textLineMD: 20px;

$textSizeMDx: 16px;
$textLineMDx: 22px;

$textSizeSM: 13px;
$textLineSM: 20px;

// Colors
$color-new-blue: #3F2AFF;
$color-text: #333333;
$color-red: #FC2C38;
$color-gray-extralight: #F8F8F8;
$color-gray-light: rgba(#00103D, 0.06);
$color-gray-muted: #919399;
$color-gray: #81859C;
$color-border: rgba(#00103D, 0.12);
$color-placeholder: #999999;
$color-warning: #FFFCE0;
$color-blue: #005BD1;
$color-overlay: rgba(#00103D, 0.48);