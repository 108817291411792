@import "../../styles/common";


.question {
    width: 400px;
    // height: 30px;
    // font-style: normal;
    // font-weight: 700;
    font-size: 24px;
    color: #24282B;
    margin-top: 100px;
    margin-bottom: 32px;
    padding-left: 120px;
}

.faq {
    width: 550px;
    margin: 0 auto;
}

.Document {
    display: flex;
    max-width: 520px;
    font-family: $font-new;
}

.Document_main {
    display: flex;
    flex-direction: column;
}

.Document_aside {
    @include mq(desktop) {
        flex: 0 0 auto;
        width: 218px;
        margin-left: 32px;
    }

    img {
        display: block;
        width: 180px;
        max-width: 100%;
        margin: auto;
    }
}

.Document_footer {

}

.Document_properties {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
}

.Document_property {
    margin: 0 0 20px;
    padding: 0;
    &:last-child {
        margin-bottom: 0;
    }
}

.Document_propertyLabel {
    //margin-bottom: 4px;
    font-weight: bold;
    // color: #148F2B;
}

.Document_identificationLabel {
    border-top: 1px solid #E4EBF0;
    width: 460px;
    padding: 25px 0px 0px 0px;
    margin-top: 25px;
    font-size: 18px;
    font-weight: bold;
}

.Document_identificationText {
    width: 460px;
    margin-top: 10px;
    font-size: 16px;
}

.Document_propertyInfoAndFile {
    width: 460px;
    // margin-bottom: 25px;
    font-weight: bold;
    color: #148F2B;
}

.Document_propertyValue {
    margin-top: 10px;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}

.Document_controls {
    // padding-top: 8px;
    // margin-top: auto;
    // margin-bottom: 0;
    // display: flex;
    // align-items: center;

    @include print {
        display: none;
    }
}

.Signin_comments {
    width: 460px;
    margin-top: 8px;
    color: #24282B;
    font-size: 16px;
    // margin-bottom: 12px;
}

.Document_signs {
    width: 460px;
}

.Document_copyButton {
    position: relative;
}

.Document_mainButton {
    // width: 460px;
    height: 56px;
    border-radius: 10px;
    font-family: $font-new;
    color: white !important;
    background-color: #24B23E !important;
    &:hover {
        background-color: #24B23E !important;
        //border: none !important;
    }
    //.button_focused {
    //    border-color: transparent !important;
    //}
}

.Document_copiedPopup {
    position: absolute;
    bottom: 100%;
    width: 150px;
    left: 50%;
    margin-bottom: 12px;
    background: #fff;
    box-shadow: 0 2px 10px rgba(#000, 0.1);
    border: 1px solid $color-border;
    border-radius: 4px;
    padding: 8px;
    opacity: 0;
    transform: translate(-50%, 20px);
    transition: 0.3s transform, 0.3s opacity;
    font-weight: normal;
    white-space: normal;

    &.animated {
        opacity: 1;
        transform: translate(-50%, 0)
    }
}

.Document_signForm {
    display: flex;
    align-items: center;
    margin-right: 12px;
    & > * {
        &:first-child {
            margin-right: 8px;
        }
    }
}

.border-line {
    padding-bottom: 30px;
    border-bottom: 1px solid black;
}