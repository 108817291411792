@import "../../styles/common";

.Dropzone {
}

.Dropzone_inner {
    cursor: pointer;
}

.Dropzone_button {
    @include button-reset;
    font-size: $textSizeMD;
    line-height: $textLineMD;
    border-bottom: 1px solid rgba($color-text, 0.5);

    &:hover {
        color: $color-red;
        border-bottom-color: rgba($color-red, 0.5);
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.Dropzone_files {
    list-style-type: none;
    margin: 20px 0 0;
    padding: 0;
    & > * {
        padding: 0;
        margin: 0 0 8px;
        font-size: $textSizeMD;
        line-height: $textLineMD;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.Dropzone_file {
    display: flex;
    align-items: center;
}

.Dropzone_fileDelete {
    @include button-reset;
    margin-right: 12px;
    width: 1.5em;
    height: 1.5em;
    align-items: center;
    justify-content: center;
    background: $color-gray-light;
    border-radius: 50%;
    &:hover {
        background: $color-red;
        color: #fff
    }
}

.Dropzone_fileName {

}