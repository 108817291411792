$tabs-margin-h: 20px/2;

.Tabs {

}

.Tabs_items {
    list-style: none;
    padding: 0;

    .Tabs__horizontal & {
        display: flex;
        align-items: center;
        margin: 0 -$tabs-margin-h;
    }

    .Tabs__vertical & {
        margin: 0;
    }
}

.Tabs_item {
    padding: 0;

    border: 1px solid #E4EBF0;

    .Tabs__horizontal & {
        // margin: $tabs-margin-h;
    }

    .Tabs__vertical & {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
