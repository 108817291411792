.DocumentsEmpty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    min-height: 50vh;
}

.DocumentsEmpty_figure {
    margin-bottom: 20px;
}

.DocumentsEmpty_image {
    margin-bottom: 16px;
}

.DocumentsEmpty_text {

}

.DocumentsEmpty_emptyUploadButton {

}