@import "../../styles/common";

.Signin {
    margin-top: 114px;
}

.Signin_title {
    margin-bottom: 18px;
    color: #24282B;
}

.Signin_comments {
    width: 460px;
    margin-bottom: 12px;
}

.timer {
    font-size: 14px;
    margin: 8px 0;
}

.Signin_footer {
    display: flex;
    align-items: center;
    & > * {
        margin-left: 24px;
        &:first-child {
            margin-left: 0;
        }
    }
}

.Signin_recovery {
    .Signin & {
        color: $color-gray-muted;
        &:hover {
            color: $color-red
        }
        &:focus {
            outline: 2px solid $color-gray-muted
        }
    }
}