.Ok_logo {
	margin-top: 42px;

	// position: absolute;
	width: 120px;
	height: 120px;
	padding-left: 180px;
	// top: 132px;
	// left: 660px;
}

.Pending_Title {
	width: 460px;
	height: 60px;
    margin-top: 40px;
	// margin-top: 284px;
    margin-bottom: 20px;
    font-size: 24px;
	text-align: center;
	align-items: center;
	flex-direction: column;
	display: flex;
}

.Panding_Com {
	/* title */

	width: 460px;
	height: 104px;

	// font-family: 'SB Sans Display';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	/* or 162% */
	text-align: center;

	color: #343434;


	/* Inside auto layout */
	flex: none;
	order: 1;
	flex-grow: 0;
}

.Documents_controls {
    width: 460px;
    
    display: flex;
    margin-bottom: 20px;

    // @include mq(desktop) {
    //     justify-content: space-between;
    //     align-items: center;
    // }
    // @include mq(mobile) {
    //     flex-direction: column;
    // }
}

.Documents_controls {
    width: 460px;
    
    display: flex;
    margin-bottom: 20px;

    // @include mq(desktop) {
    //     justify-content: space-between;
    //     align-items: center;
    // }
    // @include mq(mobile) {
    //     flex-direction: column;
    // }
}

.Documents_comment_1 {
	/* title */
	/* Line 3 */

	border-top: 1px solid #E4EBF0;

	width: 460px;
	height: 54px;

	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	/* or 129% */
	text-align: center;

	/* 🌕 Text&Icons/General/Secondary */
	color: #8C959C;


	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;

}

.Documents_comment_2 {
	/* title */

	width: 460px;
	height: 36px;

	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: center;

	color: #8C959C;
	flex: none;
	order: 1;
	flex-grow: 0;
}
