@import "../../styles/common";

.Filepicker {
    @include button-reset;
    font-size: $textSizeMD;
    line-height: $textLineMD;
    border-bottom: 1px solid rgba($color-text, 0.5);

    &:hover {
        border-bottom-color: transparent;
    }
}

.Filepicker_disabled {
    opacity: 0.5;
    pointer-events: none;
}