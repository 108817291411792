@import "../../styles/common";

.Input {
    &.input_size_s {        
        .input__control {
            outline: none;
            font-size: 18px;
            height: 48px;
            padding: 0 16px;
            width: 328px;
        }

        .input__box {
            padding: 0;
            height: 48px;
            border-radius: 12px;
        }

        &:hover {
            background-color: #F7F7F5;
        }

        &.input_pin {
            .input__control {
                letter-spacing: 10px;
            }
        }

        &.input_er {
            .input__box {
                background-color: #FFE7E7;
            }
        }
    }

    &.input_has-value {
        background-color: #F7F7F5 !important;
    }

    .input__control {
        &::placeholder {
            color: $color-placeholder;
        }
    }

    .input__box {
        // width: 460px;
        border: 1px solid $color-border;
        // border-radius: $corner-radius;
        box-shadow: none;
        //background: none !important;
    }

    &.input_focused .input__box {
        // border: 10px solid $color-gray-muted;
        border: 1px solid #AAEEB7;
        background-color: #FFFFFF;
    }

    &.input_invalid .input__box {
        border-color: $color-red;
    }

    &.input_focused {
        background-color: #FFFFFF;
    }

}