@import "../../styles/common";

.Documents {
    width: 460px;
}

.Documents_controls {
    width: 460px;
    
    display: flex;
    margin-bottom: 20px;

    @include mq(desktop) {
        justify-content: space-between;
        align-items: center;
    }
    @include mq(mobile) {
        flex-direction: row;
    }
}

.Documents_tabs {
    @include mq(mobile) {
        order: 1;
        margin-top: 20px;
    }
}

.Documents_add {
}

.Documents_filters {
    display: flex;

    @include mq(desktop) {
        margin-bottom: 24px;
    }

    @include mq(mobile) {
        flex-direction: column;
    }

    & > * {
        flex: 1 1 auto;
        @include mq(desktop) {
            margin-bottom: 0;
            &:first-child {
                flex: 0 0 auto;
                width: 33%;
                margin-right: 12px;
            }
        }

        @include mq(mobile) {
            margin-bottom: 20px;
        }
    }
}