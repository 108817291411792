@import "../../styles/common";

.DocumentSignForm {
    //min-width: 327px;
    //@include mq(desktop) {
    //    width: 512px;
    //}
}

// .Signin_comments {
//     width: 460px;
//     margin-bottom: 12px;
// }

.signature {
    width: 460px;
    // margin-bottom: 8px;
    // margin-top: 114px;
}

.signatureComments {
    width: 460px;
    margin-top: 8px;
    margin-bottom: 24px;

}

.textSign {
    width: 400px;
    size: 16px;
    font-size: #24282B;
}

.Signin_comments {
    width: 460px;
    margin-bottom: 12px;
}

.DocumentSignForm_inner {
    width: 460px;
    // display: flex;
    // align-items: center;
    & > * {
        margin-bottom: 16px;
    }
}