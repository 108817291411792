html {
    overflow-y: scroll;
}

body {
    -webkit-font-smoothing: antialiased;
    font-family: $font-regular;
    //background: $color-gray-extralight;
    background: #FFFFFF;
    @include print {
        background: none;
    }
}

.ReactModal__Body--open {
    overflow: hidden;
}

* {
    box-sizing: border-box;
}