@import "../../styles/common";

.Button {
    width: 460px;
    height: 56px;
    
    border-radius: 12px;

    .button__text {
        font-size: 16px;
    }

    .button__icon {
        margin: 0;
        padding: 0;
    }
}

.Button.Button__noSizeChange {
    .button__text {
        font-size: 18px;
    }
}

.Button.button_view_extra {
    background: #24B23E;
    border-color: #24B23E;
}

.button_view_extra.button_hovered {
        background-color: #1D9032 !important;
        border-color: #1D9032 !important;
        color: #FFFFFF
}

.Button.button_view_action {
    background: #1D9032;
    border-color: #1D9032;
    &:hover {
        background-color: #000000 !important;
        border-color: #000000 !important;
        color: #FFFFFF
    }
}

.Button.Button__active {
    border-color: #1D9032;
    border-width: 2px;
    &:hover {
        background-color: #000000 !important;
        border-color: #000000 !important;
        color: #FFFFFF
    }

    .button__text {
        font-weight: bold;
    }
}

.Button.Button__transparent {
    width: 255px;
    border-color: transparent;

    .button__text {
        border-bottom: 1px solid;
    }

    // &:hover {
    //     border-color: #000000;
    // }
}

.Button.Button__registration {
    width: 224px;
    border-color: transparent;

    .button__text {
        border-bottom: 1px solid;
    }

    // &:hover {
    //     border-color: #000000;
    // }
}

.Button.button__registration_active {
    width: 224px;

    color: #E4EBF0;
    background: #24B23E;
    border-color: #24B23E;
    // border: 1px solid #E4EBF0;

    &:hover {
        color: #FFFFFF;
        // border-color: #E4EBF0 !important;
    }
}

.Button.button__docs {
    width: 224px;
    height: 42px;
    border-color: transparent;
    border: 3px solid #21A0384D;

    .button__text {
        border-bottom: 1px solid;
    }

    &:hover {
        // border-color: #000000;
    }
}

.Button.button__docs_active {
    width: 224px;
    height: 42px;

    color: #E4EBF0;
    background: #24B23E;
    border-color: #24B23E;
    // border: 1px solid #E4EBF0;

    &:hover {
        color: #FFFFFF;
        // border-color: #E4EBF0 !important;
    }
}

.button_disabled {
    background-color: #00000020 !important;
    border-color: #000000;
}